@if (headerService.isVisible()) {
  <div class="sticky-header">
    <div class="header-container">
      <div class="content-left">
        <div class="logo-container">
          <img
            (click)="routeToHome()"
            class="mypersonal-gpt-logo"
            [src]="
              authService.tenantAliasAvailable
                ? uiCustomizationService.getHeaderLogoData(authService.tenantAlias)?.path
                : logoConfig['consileon'].path
            "
            [width]="
              authService.tenantAliasAvailable
                ? uiCustomizationService.getHeaderLogoData(authService.tenantAlias)?.width
                : logoConfig['consileon'].width
            "
            [height]="
              authService.tenantAliasAvailable
                ? uiCustomizationService.getHeaderLogoData(authService.tenantAlias)?.height
                : logoConfig['consileon'].height
            "
            alt="mypersonalgpt-logo"
            fetchpriority="high"
          />
        </div>
        <app-sidebar></app-sidebar>
      </div>
      <div class="content-center">
        @if (this.authService.user$ | async) {
          <button
            class="p-button topnav-element no-border"
            [class.header-selected-element]="activeTab() === Page.ProjectDashboard"
            (click)="routeToProjectsOverview()"
          >
            Meine Projekte
          </button>
        }
        <button
          class="p-button topnav-element no-border"
          [class.header-selected-element]="activeTab() === Page.Start"
          (click)="routeToHome()"
        >
          Startseite
        </button>
        <button
          class="p-button topnav-element no-border"
          [class.header-selected-element]="activeTab() === Page.Documentation"
          (click)="routeToDocumentation()"
        >
          Dokumentation
        </button>
      </div>
      <div class="content-right">
        <div style="padding-right: 1rem">
          @if (this.authService.user$ | async) {
            @if (inviteService.nrInvites() > 0) {
              <p-splitButton
                class="no-border secondary-button"
                [label]="authService.getUserFromToken()?.displayName ?? ''"
                [model]="profilMenuOptions"
              ></p-splitButton>
            } @else {
              <p-splitButton
                class="no-border"
                [label]="authService.getUserFromToken()?.displayName ?? ''"
                [model]="profilMenuOptions"
              ></p-splitButton>
            }
          } @else {
            <button class="no-border topnav-element p-button" (click)="routeToLogin()">Login</button>
          }
          <!-- <p-splitButton class="change-language-button" label="DE"></p-splitButton> -->
        </div>
      </div>
    </div>
  </div>
}

<p-dialog header="User Löschen" [(visible)]="deleteDialogVisible">
  <p>Wollen Sie Ihren User wirklich <strong>löschen</strong>?</p>
  <br />
  <div style="display: flex; justify-content: space-between">
    <p-button
      label="LÖSCHEN"
      (click)="deleteDialogVisible = false; authService.deleteAccount()"
      [text]="true"
    ></p-button>
    <p-button label="Zurück" (click)="deleteDialogVisible = false" [text]="true"></p-button>
  </div>
</p-dialog>
