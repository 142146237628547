@if (isVisible()) {
  <div class="footer-content">
    <div class="footer-links">
      <a href="https://www.consileon.de/datenschutz/">Datenschutzerklärung</a>
      <a href="https://www.consileon.de/datenschutzinformation/">Datenschutzinformationen</a>
      <a href="https://www.consileon.de/impressum/">Impressum</a>
    </div>
    <div class="copyright">&copy; Consileon {{ currentYear }}</div>
  </div>
}
