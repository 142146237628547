import { Injectable } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { HeaderService } from './header.service';
import { FooterService } from './footer.service';
import { AuthService } from './auth.service';

import { PATH_LOGIN, PATH_PROJECT, PATH_PROJECTS_OVERVIEW } from '../app.routes';

@Injectable({
  providedIn: 'root',
})
export class RouteWatcherService {
  private headerAndFooterHidden = false;

  constructor(
    private router: Router,
    private headerService: HeaderService,
    private footerService: FooterService,
    private authService: AuthService,
  ) {}
  initialize() {
    this.router.events
      .pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.endsWith(`/${PATH_LOGIN}`) || event.url.endsWith(`/${PATH_LOGIN}#redirecting`)) {
          this.hideHeaderAndFooter();
        } else {
          if (this.headerAndFooterHidden) {
            this.showHeaderAndFooter();
          }
        }
      });

    this.router.events
      .pipe(filter((event: Event): event is NavigationStart => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (
          (event.url.includes(PATH_PROJECT) || event.url.includes(PATH_PROJECTS_OVERVIEW)) &&
          !this.authService.isLoggedIn
        ) {
          const splitUrl = event.url.split('/');
          this.router.navigate([splitUrl[1]]);
        }
      });
  }

  private hideHeaderAndFooter() {
    this.headerService.isVisible.set(false);
    this.footerService.isVisible.set(false);
    this.headerAndFooterHidden = true;
  }

  private showHeaderAndFooter() {
    this.headerService.isVisible.set(true);
    this.footerService.isVisible.set(true);
    this.headerAndFooterHidden = false;
  }
}
