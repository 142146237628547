import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SplitButtonModule } from 'primeng/splitbutton';
import { AuthService } from '../../services/auth.service';
import { HeaderService } from '../../services/header.service';
import { InviteListenerService } from '../../services/invite-listener.service';
import { MenuItem } from 'primeng/api';
import { SidebarComponent } from 'src/app/sidebar/sidebar.component';
import { DialogModule } from 'primeng/dialog';
import { colors } from 'src/environments/custom-config/custom-configs';
import { UiCustomizationService } from 'src/app/services/ui-customization.service';

export enum Page {
  Start = 'start',
  ProjectsOverview = 'projects-overview',
  ProjectDashboard = 'project-dashboard',
  Documentation = 'documentation',
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, SplitButtonModule, SidebarComponent, DialogModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  protected activeTab = signal(Page.Start);

  profilMenuOptions: MenuItem[] = [
    { label: 'Logout', command: () => this.authService.signOut() },
    //  , {label: 'User Löschen', command: () => this.openDetelteUserDialog()}
  ];
  deleteDialogVisible = false;
  public logoConfig = colors;

  constructor(
    private router: Router,
    public authService: AuthService,
    public headerService: HeaderService,
    public inviteService: InviteListenerService,
    public uiCustomizationService: UiCustomizationService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleTabHighlighting(event.url);
      }
    });
  }

  routeToLogin() {
    if (this.authService.tenantAliasAvailable) {
      // at header level at the current app design we cannot access an ActivatedRoute in order to route relative
      this.router.navigate([this.authService.tenantAlias, 'login']);
    } else this.router.navigate(['consileon', 'login']);
  }

  routeToHome() {
    if (this.authService.isLoggedIn) {
      this.router.navigate([this.authService.tenantAlias]);
    } else {
      this.router.navigate(['']);
    }
  }

  routeToDocumentation() {
    if (this.authService.isLoggedIn) {
      this.router.navigate([this.authService.tenantAlias, 'documentation']);
    } else {
      this.router.navigate(['documentation']);
    }
  }

  routeToProjectsOverview() {
    if (this.authService.isLoggedIn) {
      this.router.navigate([this.authService.tenantAlias, 'projects-overview']);
    } else this.routeToLogin();
  }

  openDeleteUserDialog() {
    this.deleteDialogVisible = true;
  }

  private handleTabHighlighting(url: string) {
    if (url === '/' || this.router.url === `/${this.authService.tenantAlias}`) {
      this.activeTab.set(Page.Start);
    } else if (url.includes(Page.ProjectDashboard) || this.router.url.includes(Page.ProjectsOverview)) {
      this.activeTab.set(Page.ProjectDashboard);
    } else if (url.includes(Page.Documentation)) {
      this.activeTab.set(Page.Documentation);
    }
  }

  protected readonly Page = Page;
}
