import { Component } from '@angular/core';
import { FooterService } from '../services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [],
})
export class FooterComponent {
  isVisible = this.footerService.isVisible;
  currentYear = new Date().getFullYear();

  constructor(public footerService: FooterService) {}
}
