import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { connectFirestoreEmulator, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { environment } from './environments/environment';
import { RouteWatcherService } from './app/services/route-watcher.service';
import { InviteListenerService } from './app/services/invite-listener.service';

export function initRouteWatcher(routeWatcher: RouteWatcherService) {
  return () => routeWatcher.initialize();
}

export function initInviteListener(inviteListener: InviteListenerService) {
  return () => inviteListener.initialize();
}
bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, ToastrModule.forRoot()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.useFirestoreEmulator) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions(getApp(), 'europe-west4');
      if (environment.useFunctionsEmulator) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      return functions;
    }),
    provideStorage(() => {
      return getStorage();
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: initRouteWatcher,
      deps: [RouteWatcherService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initInviteListener,
      deps: [InviteListenerService],
      multi: true,
    },
    provideAnimations(),
    provideRouter(routes),
  ],
}).catch((err) => console.error(err));
